import React from 'react';
import Carousel from '../views/index-sections/Carousel.js';
import {
  Button,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Row,
  Col,
} from 'reactstrap';

import useWindowDimensions from '../utils/window-width';
export default function BrandsPresentation(props) {
  const { items } = props;
  return (
    <>
      <div>
        <Carousel itemsPerSlide={useWindowDimensions().width < 768 ? 1 : items.length>2?3:items.length} items={items} />
      </div>
    </>
  );
}
