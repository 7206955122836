import React from 'react';
import useFetchPost from '../../http/post-request';
// reactstrap components
import {
  Button,
  Card,
  Col,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Row,
  Label,
  FormGroup,
} from 'reactstrap';

// core components

function CatalogForm(props) {
  const { title, emailToSendForm, category } = props;
  const [firstFocus, setFirstFocus] = React.useState(false);
  const [lastFocus, setLastFocus] = React.useState(false);
  const [emailFocus, setEmailFocus] = React.useState(false);

  const [email, setEmail] = React.useState('');
  const [name, setName] = React.useState('');
  const [loading, setLoading] = React.useState('');
  const [formMessage, setformMessage] = React.useState('');
  const [phone, setPhone] = React.useState('');
  const [shareContact, setshareContact] = React.useState(false);

  React.useEffect(() => {
    document.body.classList.add('landing-page');
    document.body.classList.add('sidebar-collapse');
    document.documentElement.classList.remove('nav-open');
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    return function cleanup() {
      document.body.classList.remove('landing-page');
      document.body.classList.remove('sidebar-collapse');
    };
  }, []);

  const sendemail = (fromEmail, fromName, product, phone, shareContact) => {
    setLoading(true);
    useFetchPost
      .call(this, 'https://emailservice.closerdev.com/api/sendemail', {
        mailTo: emailToSendForm,
        subject: 'Client contact',
        fromName: name,
        nome: fromName,
        email: fromEmail,
        phone,
        message: `<br/><br/>Viva! <br/> \n\n

        O Cliente ${fromEmail} (${fromName}) pediu o catálogo referente a ${product} e ${
          !!shareContact ? '' : '<b><u>não</u></b>'
        } deseja partilhar o contacto para acções de marketing futuras.\n\n<br/><br/>

        Cumprimentos`,
      })
      .then((res) => {
        setLoading(false);

        setformMessage('Mensagem enviada!');

        setTimeout(() => {
          setformMessage('');
          setEmail('');
          setName('');
        }, 5000);
      })
      .catch((err) => {
        setLoading(false);
        setformMessage('Aconteceu algo de errado...');
        console.log(err);
      });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    // trackClickAnalytics(
    //   'Pedir Catálogo',
    //   'Click',
    //   'Enviar formulário',
    //   props.index
    // );
    sendemail.call(this, email, name, category, phone, shareContact);
  };
  return (
    <>
      <div className='section' id='contact'>
        <Container>
          <Row>
            <Col sm='0' md='3'></Col>
            <Col sm='12' md='6'>
              <Card
                className='card-catalog mx-auto'
                data-background-color='blue'
              >
                <Form onSubmit={handleSubmit} className='form'>
                  <CardHeader className='text-center'>
                    <CardTitle className='title-up' tag='h3'>
                      {title}
                    </CardTitle>
                  </CardHeader>
                  <CardBody>
                    <InputGroup
                      className={
                        'no-border' + (firstFocus ? ' input-group-focus' : '')
                      }
                    >
                      <InputGroupAddon addonType='prepend'>
                        <InputGroupText
                          key={Math.random()
                            .toString(36)
                            .substr(2, 9)
                            .toString()}
                        >
                          <i className='fas fa-users'></i>
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input
                        placeholder='Nome'
                        type='text'
                        onFocus={() => setFirstFocus(true)}
                        onBlur={() => setFirstFocus(false)}
                        onChange={(e) => setName(e.target.value)}
                        required
                        value={name}
                      ></Input>
                    </InputGroup>
                    <InputGroup
                      className={
                        'no-border' + (lastFocus ? ' input-group-focus' : '')
                      }
                    >
                      <InputGroupAddon addonType='prepend'>
                        <InputGroupText
                          key={Math.random()
                            .toString(36)
                            .substr(2, 9)
                            .toString()}
                        >
                          <i className='fas fa-phone'></i>
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input
                        placeholder='Contacto telefónico'
                        type='text'
                        onFocus={() => setLastFocus(true)}
                        onBlur={() => setLastFocus(false)}
                        onChange={(e) => setPhone(e.target.value)}
                        value={phone}
                        required
                        min={9}
                      ></Input>
                    </InputGroup>
                    <InputGroup
                      className={
                        'no-border' + (emailFocus ? ' input-group-focus' : '')
                      }
                    >
                      <InputGroupAddon addonType='prepend'>
                        <InputGroupText
                          key={Math.random()
                            .toString(36)
                            .substr(2, 9)
                            .toString()}
                        >
                          <i className='fas fa-envelope'></i>
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input
                        placeholder='Email'
                        type='text'
                        onFocus={() => setEmailFocus(true)}
                        onBlur={() => setEmailFocus(false)}
                        onChange={(e) => setEmail(e.target.value)}
                        value={email}
                        required
                      ></Input>
                    </InputGroup>
                    <FormGroup check>
                      <Label style={{ color: 'white' }} check>
                        <Input
                          type='checkbox'
                          value={shareContact}
                          onChange={(e) => setshareContact(e.target.value)}
                        ></Input>
                        <span className='form-check-sign white'></span>
                        Quero partilhar os meus dados para acções de marketing
                        futuras
                      </Label>
                    </FormGroup>
                  </CardBody>
                  <CardFooter className='text-center'>
                    <h5>
                      <b>
                        {formMessage && formMessage !== '' ? formMessage : ''}
                      </b>
                    </h5>
                    {!loading ? (
                      <Button
                        className='btn-neutral btn-round'
                        color='info'
                        size='lg'
                        type='submit'
                      >
                        Submeter
                      </Button>
                    ) : (
                      <div className='loader white'>Loading...</div>
                    )}
                  </CardFooter>
                </Form>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
}

export default CatalogForm;
