import React from 'react';
import Carousel from '../views/index-sections/Carousel.js';
import { Row, Col } from 'reactstrap';

export default function ProductPresentation(props) {
  const { title, items, description } = props;
  return (
    <>
      <div>
        <h4>{title}</h4>
        <Carousel items={items} />
        <br></br>
        <Row className='mt-3'>
          <Col className='flex column center pl-3 pt-3' sm='12'>
            <p className='description'>{description}</p>
          </Col>
        </Row>
      </div>
    </>
  );
}
